.contact-container {
  min-height: 80vh;
  .slidein {
    animation-duration: 0.5s;
    animation-name: slidein;
  }

  .slideout {
    animation: slideout 0.5s forwards;
  }

  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  @keyframes slideout {
    from {
      margin-left: 0%;
      width: 100%;
    }

    to {
      margin-left: 100%;
      width: 300%;
    }
  }
}

.menu-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .paragraph {
    font-family: "Arapey", serif;
  }

  .transition-hover {
    transition: 0.2s;
  }

  @media only screen and (max-width: 640px) {
    .slideout-menu {
      animation: slideout-menu 0.5s forwards;
    }
  }

  @media only screen and (max-width: 640px) {
    .slidein-menu {
      animation-duration: 0.5s;
      animation-name: slidein-menu;
    }
  }

  @keyframes slidein-menu {
    from {
      margin-left: -100%;
    }

    to {
      margin-left: 0%;
    }
  }

  @keyframes slideout-menu {
    from {
      margin-left: 0%;
    }

    to {
      margin-left: -100%;
    }
  }
}

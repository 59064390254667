.home-container {
  max-width: 1200px;
  .inner-container {
    min-height: 80vh;
  }

  .card-width {
    width: 510px;
    @media (max-width: 640px) {
      width: 400px;
    }
  }

  .card-width-carousel {
    width: 650px;
    @media (max-width: 640px) {
      width: 400px;
    }
  }

  .img-container {
    .home-profile-picture {
      border-radius: 10000px;
      width: 250px;
      height: 250px;
    }
  }

  .cards-container {
    width: 50%;
  }

  .slidein {
    animation-duration: 0.5s;
    animation-name: slidein;
  }

  .slideout {
    animation: slideout 0.5s forwards;
  }

  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  @keyframes slideout {
    from {
      margin-left: 0%;
      width: 100%;
    }

    to {
      margin-left: 100%;
      width: 300%;
    }
  }

  .collapse {
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
}

/* Tailwind css global classes imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Google fonts */
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arapey:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");

body {
  background-color: #f6f6f6;
  margin: 0;
}

.App {
  min-height: 100vh;
}

.transition-hover {
  transition: 0.2s;
}

.text-lato {
  font-family: "Lato", sans-serif;
}

.text-montserrat {
  font-family: "Montserrat", sans-serif;
}

.paragraph {
  font-family: "Arapey", serif;
}

.css-10hburv-MuiTypography-root {
  font-size: 12px !important;
}

.css-1e9lk82-MuiListItemAvatar-root {
  min-width: unset !important;
  margin-right: 4px;
}

.css-1p823my-MuiListItem-root {
  padding: 0 !important;
}
